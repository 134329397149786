import Rails from 'rails-ujs';
Rails.start();

Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

$(document).ready(function() { $("a[href^='http']").not("a[href*='gte.cityguideny.com']").not("a[href*='cityguide.places.events']").not("a[href*='places.test']").not("a[href*='localhost']").attr('target','_blank').attr('rel','noopener');
  $("a[href^='http://places.test/links/warn']").attr('target','_blank').attr('rel','noopener');
  $("a[href^='https://gte.cityguideny.com/links/warn']").attr('target','_blank').attr('rel','noopener');
  $("a[href^='http://localhost:3000/links/warn']").attr('target','_blank').attr('rel','noopener');
  
	$('#autocomplete-city').autocomplete({
      minChars: 3,
      autoSelectFirst: true,
      lookup: function (query, done) {
        var result = {};
        var suggestions = [];
        
        var start_date = $('#start_date').val();
        var end_date = $('#end_date').val();
        var dates_uri='?';
        if(start_date.length>0) dates_uri += 'start_date=' + start_date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2") + "&";
        if(end_date.length>0) dates_uri += 'end_date=' + end_date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
        
        $.ajax({
            type: 'GET',
            // CORS-enabled API
            url: "/api/v1/search/"+ encodeURIComponent(query).replace(/\./g, ';') + dates_uri,
            dataType: "json",
            success: function (json) {
                $("#city_id").val('');
                var array = json;
                for (var key in array) {
                    if (array.hasOwnProperty(key)) {
                        var thisLabel = '';
                        if(array[key].categories && array[key].categories.length > 0) thisLabel = array[key].categories[0];
                        if(array[key].city) thisLabel += (thisLabel.length == 0 ? '' : ', ') + array[key].city;
                        suggestions.push({"value": array[key].name, "data": array[key].webpage_url, "additional": thisLabel, "type": array[key].type});
                    }
                }
                result["suggestions"] = suggestions;
                done(result);
            },

            error: function (ajaxContext) {
                console.log(ajaxContext.responseText)
            }
        });
      },
      formatResult: function (suggestion, currentValue) {
        var label = "<strong>" + suggestion.value + '</strong>';
        if(suggestion.additional.length > 0) label += ', ' + suggestion.additional;
        return label;
      },
      beforeRender: function (container, suggestions) {
        var $link = $("<a>", {
          href: "#",
          class: "search-bt see-all"
        }).html("View all").click(function(e) {
          e.preventDefault();
          var t = '/search?text='+$('#autocomplete-city').val();
          if($('#start_date').val().length && $('#end_date').val().length) {
            var start_date = $('#start_date').val();
            var end_date = $('#end_date').val();
            t += '&start_date='+start_date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2")+'&end_date='+end_date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
          }
          document.location.href=encodeURI(t);
        }).appendTo(container);
      },  
      onSelect: function (suggestion) {
        $("#city_id").val(suggestion.data);
        $("#autocomplete-city").data('search-type',suggestion.additional);
        if(suggestion.additional.length > 0 || suggestion.type === "region") 
          document.location.href = suggestion.data;
      }
	}).focus(function(){
    var that = this;
    setTimeout(function(){ that.selectionStart = that.selectionEnd = 10000; }, 0);
  });
  
  $('#start_date').datepicker({
      startDate: '+0d',
      format: 'mm/dd/yyyy',
      autoclose: true,
      zIndexOffset: 10000
  }).on('changeDate', function(e) {
    $('#end_date').datepicker('setStartDate',e.date);
    $('#end_date').datepicker('setEndDate',e.date.addDays(30));
    $('#end_date').datepicker('setDate', e.date);
  });;
  
  if($('#start_date').val()==="") {
    $('#end_date').datepicker({
        startDate: '+0d',
        endDate: '+30d',
        format: 'mm/dd/yyyy',
        autoclose: true,
        zIndexOffset: 10000
    });  
  } else {
    $('#end_date').datepicker({
        startDate: $('#start_date').val(),
        endDate: moment($('#start_date').val()).add(1, 'months').calendar(),
        format: 'mm/dd/yyyy',
        autoclose: true,
        zIndexOffset: 10000
    });  
  }
  
  $("#no_dates").click(function(event) {
    if($(this).is(':checked')) {
      $('input.date').prop('disabled','disabled');
      $('#start_date,#end_date').val('');
    } else $('input.date').prop('disabled',false);
  });
  
  $('#home-search-btn').click(function(e) {
    e.preventDefault();
    var dates_uri = '';
    if(!$("#no_dates").is(':checked')) {
      var start_date = $('#start_date').val();
      var end_date = $('#end_date').val();
      if(start_date.length>0) dates_uri += '/' + start_date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
      if(end_date.length>0) dates_uri += '/' + end_date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
    }
    if ($("#city_id").val().indexOf('/livestream/livestream-and-virtual') != -1) {
      document.location.href = $("#city_id").val();
    } else {
      if ($("#city_id").val() !== "") {
          document.location.href = $("#city_id").val() + dates_uri + '/all';
      }  else  {
        document.location.href = '/search?text='+ $("#autocomplete-city").val() + '&start_date=' + start_date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2") + '&end_date=' + end_date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
      }
    }
    return false;
  });
  
  $('#show-mobile-search').click(function() {
    $('.top-search-form').toggle();
  });
  
  // browser window scroll (in pixels) after which the "back to top" link is shown
  var _offset = 300,
  //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
  _offset_opacity = 500,
  //duration of the top scrolling animation (in ms)
  _scroll_top_duration = 700,
  //grab the "back to top" link
  $back_to_top = $('.cd-top');

  //hide or show the "back to top" link
  $(window).scroll(function(){
      ( $(this).scrollTop() > _offset ) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
      if( $(this).scrollTop() > _offset_opacity ) { 
          $back_to_top.addClass('cd-fade-out');
      }
  });

  //smooth scroll to top
  $back_to_top.on('click', function(event){
      event.preventDefault();
      $('body,html').animate({
          scrollTop: 0 ,
          }, _scroll_top_duration
      );
  });
  
  $("#accept_cookies").click(function() {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + 365);
      var cookie = [ 'cookieconsent_status=allow', 'expires=' + exdate.toUTCString(), 'path=/' ];
      document.cookie = cookie.join(';');
      dataLayer.push({ 'event': 'cookieconsent_status' });
      alert('Use of cookies is accepted')
  });
  $("#decline_cookies").click(function() {
      var essential = ["cookieconsent_status", "_gte_session", "__stripe_mid"];
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {            
          var cookie = cookies[i]; var eqPos = cookie.indexOf("="); var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          if (essential === undefined || essential.length == 0 || essential.indexOf(name.trim()) == -1) { document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"; }
      }
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + 365);
      var cookie = [ 'cookieconsent_status=deny', 'expires=' + exdate.toUTCString(), 'path=/' ];
      document.cookie = cookie.join(';');
      alert('Use of cookies is declined')
  });
  
  $("#link-ok-btn").click(function() {
    $('#link-follow-form').submit();
    return false;
  });
});